<template>
	<div>
		<Header></Header>
		<div class="common-header">
			<div class="search-healder">
				<div class="logo-box">
					<img @click="toUrl('/')" class="logo" src="../../image/index/logo.png" />
				</div>
				<div class="search">
					<input placeholder="请输入商品名称" v-model="name" />
					<div class="search-btn el-icon-search" @click="search()"> </div>
				</div>
			</div>
		</div>
		<div class="order-content">
			<div class="order-box">
				<table class="bought-table-mod__table___AnaXt table-head-mod__table">
					<tbody>
						<tr>
							<th style="width: 40%;">商品</th>
							<th style="width: 12%;">单价</th>
							<th style="width: 12%;">数量</th>
							<th style="width: 12%;">实付款</th>
							<th style="width: 12%;">交易状态</th>
							<th style="width: 12%;">交易操作</th>
						</tr>
					</tbody>
				</table>
				<table border="1" class="bought-table-mod__table___AnaXt bought-wrapper-mod__table___3xFFM"
					v-for="(order,index) in list" :key="index">
					<colgroup>
						<col style="width:40%;" />
						<col style="width:12%;" />
						<col style="width:12%;" />
						<col style="width:12%;" />
						<col style="width:12%;" />
					</colgroup>
					<tbody class="bought-wrapper-mod__head">
						<tr>
							<td style="width: 100%;" class="bought-wrapper-mod__head-info-cell" colspan="6">
								订单编号:{{order.orderCode}}
							</td>
						</tr>
					</tbody>
					<tbody>
						<tr v-for="(item,i) in order.itemList" :key="i" style="width: 100%;">
							<td class="sol-mod__no-br" style="width: 40%;">
								<div class="production-mod__production">
									<div class="ml-mod__media">
										<img :src="item.itemPic" v-if="!order.recipel"/>
										<img src="../../assets/drug.jpg" v-if="order.recipel"/>
									</div>
									<div style="margin-left: 90px;">
										<p>{{item.itemName}}</p>
										<p style="color: #9e9e9e;line-height: 16px;">{{item.skuName}}</p>
									</div>
								</div>
							</td>
							<td class="sol-mod__no-br" style="width: 12%;">
								<div class="price-mod__price">
									<p>￥{{item.itemPrice}}</p>
								</div>
							</td>
							<td class="sol-mod__no-br" style="width: 12%;">
								<div class="price-mod__price">
									<p>{{item.itemNum}}</p>
								</div>
							</td>
							<td style="width: 12%;" class="" :rowspan="order.itemList.length" v-if="i==0">
								<div class="price-mod__total">
									<p>￥{{order.payPrice}}</p>
									<p>(含运费)</p>
								</div>
							</td>
							<td style="width: 12%;" class="" :rowspan="order.itemList.length" v-if="i==0">
								<div class="price-mod__toll">
									{{getStatusName(order.status)}}
									<div v-if="order.deliveryId">
										<div>物流名称:{{order.deliveryName}}</div>
										<div>物流单号:{{order.deliveryId}}</div>
									</div>
								</div>
							</td>
							<td style="width: 12%;" class="" :rowspan="order.itemList.length" v-if="i==0">
								<div class="price-mod__toll tool">
									<div @click="scrap(index,order.id)" v-if="order.status==2">取消订单</div>
									<div @click="cancel(index,order.id)" v-if="order.status==0">取消支付</div>
									<div v-if="order.status==3">确认收货</div>
									<div @click="del(index,order.id)">删除订单</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="page" v-if="total>list.length">
				<el-pagination :hide-on-single-page="true" v-if="list.length>0" :current-page="params.page"
					:page-size="params.pageSize" layout="prev, pager, next" :total="total"
					@current-change="currentChange" />
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import {
		orderList,
		orderScrap,
		orderCancel,
		orderDel
	} from '@/api/order/order'
	export default {
		data() {
			return {
				list: [],
				total: 0,
				name: '',
				params: {
					data: {
						orderTypeList: [0, 3, 4],
						delFlagList:[1]
					},
					page: 1,
					pageSize: 10
				},
				statusList: [{
					status: 0,
					name: '等待付款',
					color: 'warning'
				}, {
					status: 2,
					name: '待发货',
					color: 'warning'
				}, {
					status: 3,
					name: '待收货',
					color: 'success'
				}, {
					status: 4,
					name: '待评价',
					color: 'success'
				}, {
					status: 5,
					name: '已完成',
					color: 'success'
				}, {
					status: 7,
					name: '取消支付',
					color: 'info'
				}, {
					status: 8,
					name: '已退款',
					color: 'warning'
				}, {
					status: 9,
					name: '售后处理中',
					color: 'warning'
				}, {
					status: 10,
					name: '已退款',
					color: 'warning'
				}, {
					status: 11,
					name: '退货退款',
					color: 'warning'
				}, {
					status: 12,
					name: '仅退款',
					color: 'warning'
				}, {
					status: 13,
					name: '待用户退货',
					color: 'danger'
				}, {
					status: 14,
					name: '待商家确认收货',
					color: 'danger'
				}, {
					status: 16,
					name: '正在核实处方单',
					color: 'danger'
				}]
			};
		},
		created() {
			this.getList()
		},
		methods: {
			search() {
				this.$router.push({
					path: '/item/search?name=' + this.name
				})
			},
			toUrl(url) {
				this.$router.push({
					path: url
				})
			},
			getStatusName(status) {
				var name = ''
				this.statusList.forEach(item => {
					if (item.status == status) {
						name = item.name
					}
				})
				return name
			},
			getList() {
				orderList(this.params).then(res => {
					this.list = res.data.data
					this.total = res.data.total
				})
			},
			sizeChange(val) {
				this.params.pageSize = val
				this.getList()
			},
			currentChange(val) {
				this.params.page = val
				this.getList()
			},
			scrap(index, id) {
				this.$confirm('确认取消订单?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					orderScrap({
						orderId: id
					}).then(res => {
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '取消成功'
							});
							this.getList()
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消操作'
					});
				});
			},
			cancel(index, id) {
				this.$confirm('确认取消订单?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					orderCancel({
						orderId: id
					}).then(res => {
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '取消成功'
							});
							this.getList()
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消操作'
					});
				});
			},
			del(index, id) {
				this.$confirm('确认删除订单?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					orderDel({
						orderId: id
					}).then(res => {
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功'
							});
							this.list.splice(index, 1)
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消操作'
					});
				});
			}
		},
	};
</script>

<style lang='scss'>
	.order-content {
		width: 80%;
		margin-left: 10%;
		min-height: 400px;

		.order-box {
			width: 100%;
			margin-top: 40px;

			.table-head-mod__table {
				margin: 10px 0;
				background-color: #f5f5f5;
				border: 1px solid #e8e8e8;
				text-align: center;

				th {
					line-height: 40px;
					font-weight: 400;
				}
			}

			.bought-table-mod__table___AnaXt {
				width: 100%;
				border-collapse: collapse;
				border-spacing: 0;
				color: #3c3c3c;
				table-layout: fixed;
				margin-top: 20px;
				border-color: #daf3ff;

				p {
					margin-top: 8px;
					margin-bottom: 8px;
					line-height: 1;
				}
			}

			.bought-wrapper-mod__head {
				background: #eaf8ff;

				.bought-wrapper-mod__head-info-cell {
					text-align: left;
					margin-left: 20px;
					line-height: 30px;
					padding-left: 20px;
				}
			}

			.sol-mod__no-br {
				border-width: 1px 1px 0 0;
				border-style: solid;
				border-color: #daf3ff;
				text-align: center;
				padding-top: 10px;
				padding-bottom: 10px;
				border-right: none !important;

				.production-mod__production {
					margin-left: 20px;
					text-align: left;

					.ml-mod__media {
						width: 80px;
						float: left;

						img {
							display: block;
							margin: 0 auto;
							max-width: 100%;
						}
					}
				}
			}

			.price-mod__total {
				font-weight: bold;
				text-align: center;
				font-size: 14px;
			}

			.price-mod__toll {
				text-align: center;
			}

			.tool {
				cursor: pointer;
				font-size: 14px;

				div {
					line-height: 30px;
				}

				div:hover {
					text-decoration: underline;
				}
			}
		}


		.page {
			margin-top: 20px;
			width: 100%;
			margin-bottom: 20px;

			.el-pagination {
				text-align: center;
			}

			button {
				background: none !important;
				font-size: 18px;

				i {
					font-size: 18px !important;
				}
			}

			.el-pager li {
				background: none;
				font-size: 18px;
				font-weight: normal !important;
			}
		}
	}
</style>